import React from "react";

type TSearchIcon = {
  fill: string;
};

const SearchIconSvg = ({ fill }: TSearchIcon) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7674 12.6749L10.3339 9.23731C12.0602 6.9904 11.8876 3.74486 9.81602 1.68999C8.68433 0.556927 7.22655 0 5.74959 0C4.27263 0 2.81485 0.556927 1.68316 1.68999C-0.561053 3.9369 -0.561053 7.58573 1.68316 9.83265C2.81485 10.9657 4.27263 11.5226 5.74959 11.5226C6.97719 11.5226 8.2048 11.1385 9.2214 10.3512L12.674 13.7695C12.8275 13.9232 13.0193 14 13.2303 14C13.4221 14 13.6331 13.9232 13.7866 13.7695C14.0743 13.4815 14.0743 12.9822 13.7674 12.6749ZM5.76877 9.96708C4.63708 9.96708 3.60129 9.52538 2.79567 8.738C1.16526 7.10562 1.16526 4.43621 2.79567 2.78464C3.58211 1.99726 4.63708 1.55556 5.76877 1.55556C6.90047 1.55556 7.93626 1.99726 8.74187 2.78464C9.54749 3.57202 9.96947 4.62826 9.96947 5.76132C9.96947 6.89438 9.52831 7.93141 8.74187 8.738C7.95544 9.54458 6.88129 9.96708 5.76877 9.96708Z"
        fill={fill}
      ></path>
    </svg>
  );
};
export default SearchIconSvg;

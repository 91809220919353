import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

import { PostListItemType } from "../../@types/PostItem.types";
import PostItem from "./PostItem";
import useInfiniteScroll, {
  useInfiniteScrollType,
} from "hooks/useInfiniteScroll";

export type PostType = {
  node: {
    id: string;
    frontmatter: {
      title: string;
      summary: string;
      date: string;
      categories: string[];
      thumbnail: {
        publicURL: string;
      };
    };
  };
};

type PostListProps = {
  selectedCategory: string;
  posts: PostListItemType[];
  searchText: string;
};

const PostListWrapper = styled.div`
  max-width: 768px;
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledPostList = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  grid-gap: 34px;
  margin: var(--pad-inner);
  @media (max-width: 768px) {
    grid-gap: 30px;
    padding: 0 2rem;
  }
`;

const PostList: FunctionComponent<PostListProps> = function ({
  selectedCategory,
  posts,
  searchText,
}) {
  const { containerRef, postList }: useInfiniteScrollType = useInfiniteScroll(
    selectedCategory,
    posts,
    searchText
  );

  return (
    <PostListWrapper>
      <StyledPostList ref={containerRef}>
        {postList.map(
          ({
            node: {
              id,
              fields: { slug },
              frontmatter,
            },
          }: PostListItemType) => (
            <PostItem {...frontmatter} link={slug} key={id} />
          )
        )}
      </StyledPostList>
    </PostListWrapper>
  );
};

export default PostList;

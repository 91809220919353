import React, { FunctionComponent, useMemo, useState } from "react";
import queryString, { ParsedQuery } from "query-string";
import { graphql } from "gatsby";

import Template from "components/common/Template";
import Header from "../components/common/Header";
import Bio from "../components/common/Bio";
import Footer from "../components/common/Footer";
import CategoryList, {
  CategoryListProps,
} from "../components/common/CategoryList";
import PostList, { PostType } from "../components/common/PostList";
import { PostListItemType } from "../@types/PostItem.types";
import SearchInput from "components/SearchInput";
import Fuse from "fuse.js";

type MainProps = {
  location: {
    search: string;
  };
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
        siteUrl: string;
      };
    };
    allMdx: {
      edges: PostListItemType[];
    };
    file: {
      publicURL: string;
    };
  };
};

const Main: FunctionComponent<MainProps> = function ({
  location: { search },
  data: {
    site: {
      siteMetadata: { title, description, siteUrl },
    },
    allMdx: { edges },
  },
}) {
  const parsed: ParsedQuery<string> = queryString.parse(search);
  const selectedCategory: string =
    typeof parsed.category !== "string" || !parsed.category
      ? "All"
      : parsed.category;

  const categoryList = useMemo(
    () =>
      edges.reduce(
        (
          list: CategoryListProps["categoryList"],
          {
            node: {
              frontmatter: { categories },
            },
          }: PostType
        ) => {
          categories.forEach((category) => {
            if (list[category] === undefined) list[category] = 1;
            else list[category]++;
          });

          list["All"]++;

          return list;
        },
        { All: 0 }
      ),
    []
  );

  // 검색 옵션 설정
  const fuseOptions = {
    keys: [
      "node.frontmatter.title",
      "node.frontmatter.summary",
      "node.fields.plainTextContent",
    ],
    includeScore: true,
    minMatchCharLength: 1,
    threshold: 0.1,
    matchAllTokens: true, // 모든 토큰이 일치하는지 확인
    maxPatternLength: 10000,
    tokenSeparator: /[^a-zA-Z0-9가-힣]+/,
    ignoreLocation: true,
  };

  // Fuse.js 인스턴스 생성
  const fuse = new Fuse(edges, fuseOptions);

  // 검색 함수 구현
  const searchPosts = (searchText: string) => {
    if (!searchText) {
      return edges;
    }

    const searchResult = fuse.search(searchText);
    return searchResult.map((result) => result.item);
  };

  // 검색어 상태 추가
  const [searchText, setSearchText] = useState("");

  // 검색 결과 생성
  const searchResult = useMemo(() => {
    return searchPosts(searchText);
  }, [searchText, edges]);

  // 검색어 변경 핸들러
  const handleSearch = (newSearchText: string) => {
    setSearchText(newSearchText);
  };

  return (
    <>
      <Template title={title} description={description} url={siteUrl}>
        <Header setSearchText={setSearchText} />
        {/* <Bio />
        <CategoryList
          selectedCategory={selectedCategory}
          categoryList={categoryList}
        /> */}
        <SearchInput
          onSearch={handleSearch}
          project={parsed.category === "Project" ? true : false}
        />
      </Template>
      <PostList
        selectedCategory={selectedCategory}
        // posts={edges}
        posts={searchResult}
        searchText={searchText}
      />
      <Footer />
    </>
  );
};

export default Main;

export const getPostList = graphql`
  query getPostList {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            summary
            date(formatString: "DD MMM YYYY")
            categories
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
    file(name: { eq: "profile-image" }) {
      publicURL
    }
  }
`;
